import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';
import { Observable, BehaviorSubject, of } from 'rxjs';
import {
  filter, map, shareReplay, switchMap, take, tap,
} from 'rxjs/operators';
import { BaseHttpService, ThemeService } from 'asap-team/asap-tools';

import type { Profile } from '@core/types';

// Consts
import { TRACKING_PROVIDER } from '@consts/consts';

// Services
import { TrackingScriptsService } from '@core/helpers/tracking-scripts/tracking-scripts.service';
@Injectable({ providedIn: 'root' })
export class UserService {

  private USER_PROFILE_TRANSFER_STATE_KEY: StateKey<Profile> = makeStateKey<Profile>('user_profile');

  private readonly profile: BehaviorSubject<Profile> = new BehaviorSubject<Profile>(null);

  private _isHomeWealthLanding: boolean = false;

  profile$: Observable<Profile> = this
    .profile
    .asObservable()
    .pipe(
      filter<Profile>(Boolean),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private transferState: TransferState,
    private http: BaseHttpService,
    private themeService: ThemeService,
    private trackingScriptsService: TrackingScriptsService,
  ) {}

  emitUserChange(profile: Profile): void {
    this.profile.next(profile);
  }

  getUserValue(): Profile {
    return this.profile.getValue();
  }

  isUserRole(role: string): boolean {
    return this.profile.getValue()?.role === role;
  }

  setlandingType(isHomeValueLanding: boolean): void {
    // eslint-disable-next-line no-underscore-dangle
    this._isHomeWealthLanding = isHomeValueLanding;
  }

  get isHomeWealthLanding(): boolean {
    // eslint-disable-next-line no-underscore-dangle
    return this._isHomeWealthLanding;
  }

  getUser(userName: string): Observable<Profile> {

    const endpoint: string = this.isHomeWealthLanding ? 'v1/home_wealth/users/' : 'v1/seller_traffic/users/';
    const request: Observable<Profile> = this
      .http
      .get(`${endpoint}${userName}`)
      .pipe(
        switchMap((profile: Profile) => this.performActionsForProfileUpdate(profile)),
      );

    if (isPlatformBrowser(this.platformId)) {
      if (this.transferState.hasKey(this.USER_PROFILE_TRANSFER_STATE_KEY)) {
        const profile: Profile = this.transferState.get(this.USER_PROFILE_TRANSFER_STATE_KEY, null);

        this.transferState.remove(this.USER_PROFILE_TRANSFER_STATE_KEY);

        return of(profile).pipe(
          switchMap((data: Profile) => this.performActionsForProfileUpdate(data)),
        );
      }

      return request;
    }

    return request;
  }

  private performActionsForProfileUpdate(profile: Profile): Observable<Profile> {
    if (isPlatformServer(this.platformId)) {
      this.transferState.set(this.USER_PROFILE_TRANSFER_STATE_KEY, profile);
    }

    this.themeService.setTheme(profile.theme || 'flamingo');
    this.emitUserChange(profile);

    return this
      .trackingScriptsService
      .ready$
      .pipe(
        take(1),
        tap((isReady: boolean) => {
          if (isReady) { return; }

          this.trackingScriptsService.init([
            {
              id: profile.ga_id,
              name: TRACKING_PROVIDER.GA,
            },
            {
              id: profile.fb_pixel_id,
              name: TRACKING_PROVIDER.FB,
            },
            {
              id: profile.gtm_id,
              name: TRACKING_PROVIDER.GTM,
            },
          ]);
        }),
        map(() => profile),
      );
  }

}
