import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { USER_ROLE } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';
import { TrackingScriptsService } from '@core/helpers/tracking-scripts/tracking-scripts.service';
@Injectable({ providedIn: 'root' })
export class AsaplyticsService {

  private isHomeValueLenderFlow: boolean = false;

  private readonly homeValueEvent: string = 'HER Stepper Lender (Home value)';

  constructor(
    private trackingScriptsService: TrackingScriptsService,
    private userService: UserService,
    private router: Router,
  ) {
    this.isHomeValueLenderFlow = router.url.includes('/home-value');
  }

  trackViewLP(): void {
    this.actionForAgentRole('View landing page (Agent)', 'HER Stepper Agent');
    this.actionForLenderRole('View landing page (Lender)', 'HER Stepper Lender');
    this.actionForLenderRoleHomeValue('View landing page (Lender)', this.homeValueEvent);
  }

  trackInputSearch(): void {
    this.actionForAgentRole('Input search address (Agent)', 'HER Stepper Agent');
    this.actionForLenderRole('Input search address (Lender)', 'HER Stepper Lender');
    this.actionForLenderRoleHomeValue('Input search address (Lender)', this.homeValueEvent);
  }

  trackClickGetStarted(): void {
    this.actionForAgentRole('Click GET STARTED (Agent)', 'HER Stepper Agent');
    this.actionForLenderRole('Click GET STARTED (Lender)', 'HER Stepper Lender');
    this.actionForLenderRoleHomeValue('Click GET STARTED (Lender)', this.homeValueEvent);
  }

  trackClickGetStartedError(): void {
    this.actionForAgentRole('Click GET STARTED Error (Agent)', 'HER Stepper Agent');
    this.actionForLenderRoleHomeValue('Click GET STARTED Error (Lender)', this.homeValueEvent);
  }

  trackViewPropertyDetails(): void {
    this.actionForAgentRole('View property details step (Agent)', 'HER Stepper Agent');
    this.actionForLenderRole('View property details step (Lender)', 'HER Stepper Lender');
    this.actionForLenderRoleHomeValue('View property details step (Lender)', this.homeValueEvent);
  }

  trackViewNameVerification(): void {
    this.actionForAgentRole('View name verification step (Agent)', 'HER Stepper Agent');
    this.actionForLenderRole('View name verification step (Lender)', 'HER Stepper Lender');
    this.actionForLenderRoleHomeValue('View name verification step (Lender)', this.homeValueEvent);
  }

  trackViewNameVerificationError(): void {
    this.actionForAgentRole('View name verification Error step (Agent)', 'HER Stepper Agent');
    this.actionForLenderRole('View name verification Error step (Lender)', 'HER Stepper Lender');
    this.actionForLenderRoleHomeValue('View name verification Error step (Lender)', this.homeValueEvent);
  }

  trackViewWhyRefinance(): void {
    this.actionForLenderRole('View why refinance step (Lender)', 'HER Stepper Lender');
  }

  trackClickTakeCash(): void {
    this.actionForLenderRole('Click TAKE CASH on why refinance step (Lender)', 'HER Stepper Lender');
  }

  trackClickRefinance(): void {
    this.actionForLenderRole('Click REFINANCE on why refinance step (Lender)', 'HER Stepper Lender');
  }

  trackViewEmployment(): void {
    this.actionForLenderRole('View employment step (Lender)', 'HER Stepper Lender');
  }

  trackViewMilitary(): void {
    this.actionForLenderRole('View military step (Lender)', 'HER Stepper Lender');
  }

  trackViewVerifyLoan(): void {
    this.actionForLenderRole('View verify loan step (Lender)', 'HER Stepper Lender');
  }

  trackViewVerifyLoanEmptyStep(): void {
    this.actionForLenderRole('View verify loan empty step (Lender)', 'HER Stepper Lender');
  }

  trackViewDoYouHaveLoan(): void {
    this.actionForLenderRole('View do you have loan step (Lender)', 'HER Stepper Lender');
  }

  trackViewSellingPlans(): void {
    this.actionForAgentRole('View selling plans step (Agent)', 'HER Stepper Agent');
    this.actionForLenderRoleHomeValue('View selling plans step (Lender)', this.homeValueEvent);

  }

  trackViewReportGeneration(): void {
    this.actionForAgentRole('View report generation step (Agent)', 'HER Stepper Agent');
    this.actionForLenderRoleHomeValue('View report generation step (Lender)', this.homeValueEvent);

  }

  trackViewEmail(): void {
    this.actionForAgentRole('View email step (Agent)', 'HER Stepper Agent');
    this.actionForLenderRole('View email step (Lender)', 'HER Stepper Lender');
    this.actionForLenderRoleHomeValue('View email step (Lender)', this.homeValueEvent);

  }

  trackViewNotification(): void {
    this.actionForAgentRole('View notification step (Agent)', 'HER Stepper Agent');
    this.actionForLenderRoleHomeValue('View notification step (Lender)', this.homeValueEvent);

  }

  trackClickNotificationYes(): void {
    this.actionForAgentRole('Click notification YES (Agent)', 'HER Stepper Agent');
    this.actionForLenderRoleHomeValue('Click notification YES (Lender)', this.homeValueEvent);

  }

  trackClickNotificationNo(): void {
    this.actionForAgentRole('Click notification NO (Agent)', 'HER Stepper Agent');
    this.actionForLenderRoleHomeValue('Click notification NO (Lender)', this.homeValueEvent);

  }

  trackViewPhone(): void {
    this.actionForAgentRole('View phone step (Agent)', 'HER Stepper Agent');
    this.actionForLenderRole('View phone step (Lender)', 'HER Stepper Lender');
    this.actionForLenderRoleHomeValue('View phone step (Lender)', this.homeValueEvent);

  }

  trackSkipPhone(): void {
    this.actionForAgentRole('Skip phone step (Agent)', 'HER Stepper Agent');
    this.actionForLenderRole('Skip phone step (Lender)', 'HER Stepper Lender');
    this.actionForLenderRoleHomeValue('Skip phone step (Lender)', this.homeValueEvent);

  }

  trackViewVerifyPhone(): void {
    this.actionForAgentRole('View verify phone step (Agent)', 'HER Stepper Agent');
    this.actionForLenderRole('View verify phone step (Lender)', 'HER Stepper Lender');
    this.actionForLenderRoleHomeValue('View verify phone step (Lender)', this.homeValueEvent);

  }

  trackSkipVerifyPhone(): void {
    this.actionForAgentRole('Skip verify phone step (Agent)', 'HER Stepper Agent');
    this.actionForLenderRole('Skip verify phone step (Lender)', 'HER Stepper Lender');
    this.actionForLenderRoleHomeValue('Skip verify phone step (Lender)', this.homeValueEvent);

  }

  trackClickResendCodeOnVerifyPhone(): void {
    this.actionForAgentRole('Click RESEND CODE on verify phone (Agent)', 'HER Stepper Agent');
    this.actionForLenderRole('Click RESEND CODE on verify phone (Lender)', 'HER Stepper Lender');
    this.actionForLenderRoleHomeValue('Click RESEND CODE on verify phone (Lender)', this.homeValueEvent);

  }

  trackClickReEnterOnVerifyPhone(): void {
    this.actionForAgentRole('Click RE-ENTER on verify phone (Agent)', 'HER Stepper Agent');
    this.actionForLenderRole('Click RE-ENTER on verify phone (Lender)', 'HER Stepper Lender');
    this.actionForLenderRoleHomeValue('Click RE-ENTER on verify phone (Lender)', this.homeValueEvent);

  }

  trackClickValidateVerifyPhone(): void {
    this.actionForAgentRole('Click VALIDATE verify phone (Agent)', 'HER Stepper Agent');
    this.actionForLenderRole('Click VALIDATE verify phone (Lender)', 'HER Stepper Lender');
    this.actionForLenderRoleHomeValue('Click VALIDATE verify phone (Lender)', this.homeValueEvent);

  }

  trackReportViewResult(): void {
    this.actionForLenderRole('View result Success (Lender)', 'HER Stepper Lender');
  }

  trackViewResultSuccess(): void {
    this.actionForAgentRole('View result success (Agent)', 'HER Stepper Agent');
    this.actionForLenderRole('View result Success Refinance (Lender)', 'HER Stepper Lender');
    this.actionForLenderRoleHomeValue('View result success (Lender)', this.homeValueEvent);

  }

  trackClickSeeReportOnResult(): void {
    this.actionForAgentRole('Click SEE REPORT on result (Agent)', 'HER Stepper Agent');
    this.actionForLenderRoleHomeValue('Click SEE REPORT on result (Lender)', this.homeValueEvent);

  }

  trackRedirectedToTheReport(): void {
    this.actionForAgentRole('Redirected to the report (Agent)', 'HER Stepper Agent');
    this.actionForLenderRoleHomeValue('Redirected to the report (Lender)', this.homeValueEvent);

  }

  trackViewResultError(): void {
    this.actionForAgentRole('View result error (Agent)', 'HER Stepper Agent');
    this.actionForLenderRole('View result error (Lender)', 'HER Stepper Lender');
    this.actionForLenderRoleHomeValue('View result error (Lender)', this.homeValueEvent);

  }

  trackViewResultCMARequest(): void {
    this.actionForAgentRole('View result CMA request (Agent)', 'HER Stepper Agent');
    this.actionForLenderRoleHomeValue('View result CMA request (Lender)', this.homeValueEvent);

  }

  trackViewResultSuccessCashOut(): void {
    this.actionForLenderRole('View result Success Cash-out (Lender)', 'HER Stepper Lender');
  }

  trackViewResultSuccessCashOutLTV(): void {
    this.actionForLenderRole('View result success Cash-out LTV (Lender)', 'HER Stepper Lender');
  }

  trackViewResultSuccessUnemployed(): void {
    this.actionForLenderRole('View result success Unemployed (Lender)', 'HER Stepper Lender');
  }

  trackClickSendQuestionOnResult(): void {
    this.actionForLenderRole('Click SEND QUESTION on result (Lender)', 'HER Stepper Lender');
  }

  trackClickViewReportOnResult(): void {
    this.actionForLenderRole('Click VIEW REPORT on result (Lender)', 'HER Stepper Lender');
  }

  clickUserContactInfo(): void {
    this.actionForAgentRole('Click chat (Agent)', 'HER Stepper Agent', 'agentChat');
    this.actionForLenderRole('Click chat (Lender)', 'HER Stepper Lender');
    this.actionForLenderRoleHomeValue('Click chat (Lender)', this.homeValueEvent);

  }

  private actionForAgentRole(action: string, category: string, label?: string): void {
    if (!this.userService.isUserRole(USER_ROLE.agent)) { return; }

    this.trackingScriptsService.send(action, category, label);
  }

  private actionForLenderRole(action: string, category: string, label?: string): void {
    if (!this.userService.isUserRole(USER_ROLE.lender) || this.isHomeValueLenderFlow) { return; }

    this.trackingScriptsService.send(action, category, label);
  }

  private actionForLenderRoleHomeValue(action: string, category: string, label?: string): void {
    if (!this.userService.isUserRole(USER_ROLE.lender) || !this.isHomeValueLenderFlow) { return; }

    this.trackingScriptsService.send(action, category, label);
  }

}
