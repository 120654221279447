import { Component, Input } from '@angular/core';

import type { Profile } from '@core/types';
@Component({
  selector: 'user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.sass'],
})
export class UserAvatarComponent {

  @Input() user: Profile;

}
